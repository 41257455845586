import React from 'react';
import './App.scss';
import Home from './components/Home';

function App() {

  return (
    <React.Fragment>
      <Home></Home>
    </React.Fragment>
  );
}

export default App;
